"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GuildMembershipToJSON = exports.GuildMembershipFromJSONTyped = exports.GuildMembershipFromJSON = void 0;
var runtime_1 = require("../runtime");
function GuildMembershipFromJSON(json) {
    return GuildMembershipFromJSONTyped(json, false);
}
exports.GuildMembershipFromJSON = GuildMembershipFromJSON;
function GuildMembershipFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'guild': json['guild'],
        'user': json['user'],
        'joinedAt': !(0, runtime_1.exists)(json, 'joinedAt') ? undefined : json['joinedAt'],
        'leftAt': !(0, runtime_1.exists)(json, 'leftAt') ? undefined : json['leftAt'],
        'pointsEarned': !(0, runtime_1.exists)(json, 'pointsEarned') ? undefined : json['pointsEarned'],
        'monthlyPointsEarned': !(0, runtime_1.exists)(json, 'monthlyPointsEarned') ? undefined : json['monthlyPointsEarned'],
        'isActive': !(0, runtime_1.exists)(json, 'isActive') ? undefined : json['isActive'],
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
    };
}
exports.GuildMembershipFromJSONTyped = GuildMembershipFromJSONTyped;
function GuildMembershipToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'guild': value.guild,
        'user': value.user,
        'joinedAt': value.joinedAt,
        'leftAt': value.leftAt,
        'pointsEarned': value.pointsEarned,
        'monthlyPointsEarned': value.monthlyPointsEarned,
        'isActive': value.isActive,
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
    };
}
exports.GuildMembershipToJSON = GuildMembershipToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GuildQuestToJSON = exports.GuildQuestFromJSONTyped = exports.GuildQuestFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function GuildQuestFromJSON(json) {
    return GuildQuestFromJSONTyped(json, false);
}
exports.GuildQuestFromJSON = GuildQuestFromJSON;
function GuildQuestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'guild': json['guild'],
        'game': json['game'],
        'name': json['name'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'rewardType': (0, _1.GuildRewardTypeFromJSON)(json['rewardType']),
        'rewardAmount': json['rewardAmount'],
        'rewardMax': !(0, runtime_1.exists)(json, 'rewardMax') ? undefined : json['rewardMax'],
        'rewardMetaData': !(0, runtime_1.exists)(json, 'rewardMetaData') ? undefined : json['rewardMetaData'],
        'rewardCategory': (0, _1.OWLogCategoryFromJSON)(json['rewardCategory']),
        'eventType': (0, _1.OWGameEventTypeFromJSON)(json['eventType']),
        'target': json['target'],
        'milestones': !(0, runtime_1.exists)(json, 'milestones') ? undefined : (json['milestones'].map(_1.GuildRewardMilestoneFromJSON)),
        'isActive': !(0, runtime_1.exists)(json, 'isActive') ? undefined : json['isActive'],
        'userStat': !(0, runtime_1.exists)(json, 'userStat') ? undefined : (0, _1.GuildQuestUserStatFromJSON)(json['userStat']),
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
    };
}
exports.GuildQuestFromJSONTyped = GuildQuestFromJSONTyped;
function GuildQuestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'guild': value.guild,
        'game': value.game,
        'name': value.name,
        'description': value.description,
        'rewardType': (0, _1.GuildRewardTypeToJSON)(value.rewardType),
        'rewardAmount': value.rewardAmount,
        'rewardMax': value.rewardMax,
        'rewardMetaData': value.rewardMetaData,
        'rewardCategory': (0, _1.OWLogCategoryToJSON)(value.rewardCategory),
        'eventType': (0, _1.OWGameEventTypeToJSON)(value.eventType),
        'target': value.target,
        'milestones': value.milestones === undefined ? undefined : (value.milestones.map(_1.GuildRewardMilestoneToJSON)),
        'isActive': value.isActive,
        'userStat': (0, _1.GuildQuestUserStatToJSON)(value.userStat),
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
    };
}
exports.GuildQuestToJSON = GuildQuestToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OWEventTypeResponseAllOfToJSON = exports.OWEventTypeResponseAllOfFromJSONTyped = exports.OWEventTypeResponseAllOfFromJSON = void 0;
var runtime_1 = require("../runtime");
function OWEventTypeResponseAllOfFromJSON(json) {
    return OWEventTypeResponseAllOfFromJSONTyped(json, false);
}
exports.OWEventTypeResponseAllOfFromJSON = OWEventTypeResponseAllOfFromJSON;
function OWEventTypeResponseAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'createdAt': !(0, runtime_1.exists)(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !(0, runtime_1.exists)(json, 'updatedAt') ? undefined : json['updatedAt'],
    };
}
exports.OWEventTypeResponseAllOfFromJSONTyped = OWEventTypeResponseAllOfFromJSONTyped;
function OWEventTypeResponseAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
    };
}
exports.OWEventTypeResponseAllOfToJSON = OWEventTypeResponseAllOfToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidateOverwolfEventTypeBodyToJSON = exports.ValidateOverwolfEventTypeBodyFromJSONTyped = exports.ValidateOverwolfEventTypeBodyFromJSON = void 0;
function ValidateOverwolfEventTypeBodyFromJSON(json) {
    return ValidateOverwolfEventTypeBodyFromJSONTyped(json, false);
}
exports.ValidateOverwolfEventTypeBodyFromJSON = ValidateOverwolfEventTypeBodyFromJSON;
function ValidateOverwolfEventTypeBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'dataStructure': json['dataStructure'],
        'partialMatch': json['partialMatch'],
    };
}
exports.ValidateOverwolfEventTypeBodyFromJSONTyped = ValidateOverwolfEventTypeBodyFromJSONTyped;
function ValidateOverwolfEventTypeBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'dataStructure': value.dataStructure,
        'partialMatch': value.partialMatch,
    };
}
exports.ValidateOverwolfEventTypeBodyToJSON = ValidateOverwolfEventTypeBodyToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GuildQuestEventLogToJSON = exports.GuildQuestEventLogFromJSONTyped = exports.GuildQuestEventLogFromJSON = void 0;
function GuildQuestEventLogFromJSON(json) {
    return GuildQuestEventLogFromJSONTyped(json, false);
}
exports.GuildQuestEventLogFromJSON = GuildQuestEventLogFromJSON;
function GuildQuestEventLogFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'userId': json['userId'],
        'game': json['game'],
        'quest': json['quest'],
        'event': json['event'],
        'eventType': json['eventType'],
        'amount': json['amount'],
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
    };
}
exports.GuildQuestEventLogFromJSONTyped = GuildQuestEventLogFromJSONTyped;
function GuildQuestEventLogToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'userId': value.userId,
        'game': value.game,
        'quest': value.quest,
        'event': value.event,
        'eventType': value.eventType,
        'amount': value.amount,
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
    };
}
exports.GuildQuestEventLogToJSON = GuildQuestEventLogToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GuildQuestUserStatToJSON = exports.GuildQuestUserStatFromJSONTyped = exports.GuildQuestUserStatFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function GuildQuestUserStatFromJSON(json) {
    return GuildQuestUserStatFromJSONTyped(json, false);
}
exports.GuildQuestUserStatFromJSON = GuildQuestUserStatFromJSON;
function GuildQuestUserStatFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'userId': json['userId'],
        'gameId': !(0, runtime_1.exists)(json, 'gameId') ? undefined : json['gameId'],
        'game': !(0, runtime_1.exists)(json, 'game') ? undefined : json['game'],
        'isCompleted': json['isCompleted'],
        'isClaimed': json['isClaimed'],
        'quest': json['quest'],
        'progress': json['progress'],
        'target': json['target'],
        'weekStart': !(0, runtime_1.exists)(json, 'weekStart') ? undefined : json['weekStart'],
        'weeklyProgress': !(0, runtime_1.exists)(json, 'weeklyProgress') ? undefined : json['weeklyProgress'],
        'isWeeklyLimitFeedbackDismissed': !(0, runtime_1.exists)(json, 'isWeeklyLimitFeedbackDismissed') ? undefined : json['isWeeklyLimitFeedbackDismissed'],
        'claimLogs': (json['claimLogs'].map(_1.OWClaimLogFromJSON)),
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
    };
}
exports.GuildQuestUserStatFromJSONTyped = GuildQuestUserStatFromJSONTyped;
function GuildQuestUserStatToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'userId': value.userId,
        'gameId': value.gameId,
        'game': value.game,
        'isCompleted': value.isCompleted,
        'isClaimed': value.isClaimed,
        'quest': value.quest,
        'progress': value.progress,
        'target': value.target,
        'weekStart': value.weekStart,
        'weeklyProgress': value.weeklyProgress,
        'isWeeklyLimitFeedbackDismissed': value.isWeeklyLimitFeedbackDismissed,
        'claimLogs': (value.claimLogs.map(_1.OWClaimLogToJSON)),
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
    };
}
exports.GuildQuestUserStatToJSON = GuildQuestUserStatToJSON;
